<template>
  <v-dialog v-model="value" persistent max-width="550px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="bg-gradient-primary-2">
          <span class="white--text">Download Approval</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <ac-combo-box
                label="User"
                v-model="userData"
                :items="companyUser"
                class="company-select"
                multiple
                :validation-rules="[v => !!v || 'Field is required']"
                @change="filterBroker(userData.id)"
              />
            </v-col>
            <v-col>
              <ac-select
                label="Year "
                v-model="currentYear"
                :items="years"
                :retain-focus="false"
                class="company-select"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ac-select
                label="From Month"
                v-model="fromMonth"
                :items="allMonths"
                :retain-focus="false"
                class="company-select"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ac-select
                label="To Month "
                v-model="toMonth"
                :items="allMonths"
                :retain-focus="false"
                class="company-select"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Cancel" color="error" outlined @click="onClose" />
          <ac-button title="Download" color="success" :loading="loading" @click="onSubmit" />
          <upload-modal v-model="showUpload" title="approval" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { emailValidator, required } from '@core/utils/validation'
// ! remove unused icons
import { mdiInformationOutline, mdiUpload } from '@mdi/js'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DownloadApprovalModal',
  components: {
    AcInput: () => import('@/components/AcInput'),
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcComboBox: () => import('@/components/AcComboBox.vue'),
    AcDatePicker: () => import('@/components/AcDatePicker'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editItem: {
      type: Object,
    },
    companyUser: {
      type: Array,
    },
    companyApprover: {
      type: Array,
    },
  },
  // ! remove unused data properties
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiUpload,
      },
      details: '',
      investmentClass: '',
      userData: null,
      brokersNumber: '',
      gtcOrder: '',
      showUpload: false,
      search: null,
      valid: false,
      loading: false,
      valid: false,
      validators: {
        required,
        emailValidator,
      },
      // * form
      security: null,
      surName: null,
      exchange: null,
      stokeCode: null,
      // selectedCompany: null,
      approver: null,
      joinDate: null,
      brokers: [],
      allMonths: [],
      toMonth: '',
      fromMonth: '',
      years: [],
      currentYear: '',
    }
  },

  computed: {
    ...mapGetters('app', ['companies', 'loggedInUser', 'selectedCompany']),
    ...mapGetters('approval', ['accountNumbers', 'getApprovals', 'securityList']),
    securityOptions() {
      if (this.checkObject(this.editItem)) {
        const { exchange, security, ticker } = this.editItem
        const options = [
          {
            name: security,
            ticker,
            exchange: exchange,
          },
        ]
        return options.concat(this.securityList)
      } else {
        return this.securityList
      }
    },
  },
  async created() {
    let startYear = 2017
    let currentYear = new Date().getFullYear()
    this.years = []
    for (let year = currentYear; year >= startYear; year--) {
      this.years.push({ id: year.toString(), name: year.toString() })
    }
    let monthData = moment.monthsShort()
    monthData.forEach((element, index) => {
      this.allMonths.push({
        id: index,
        name: element,
      })
    })
  },
  methods: {
    ...mapActions('approval', [
      'createApproval',
      'updateApproval',
      'fetchSecurityList',
      'downloadApproval',
    ]),
    ...mapMutations('approval', ['SET_APPROVAL', 'GET_ACCOUNT', 'SET_SECURITY']),
    filterBroker(id) {
      this.brokers = this.accountNumbers.filter(account => account.user_id == id)
    },
    // FOR ADD APPROVALS......
    async onSubmit() {
      let userArray = []
      let userVal = ''
      if (this.userData) {
        this.userData.forEach(element => {
          userArray.push(element.id)
          userVal = userArray.join(',')
        })
      }
      let payload = {
        users: userVal,
        startMonth: this.fromMonth,
        endMonth: this.toMonth,
        year: this.currentYear,
        company: this.selectedCompany,
      }
      const { success, message } = await this.downloadApproval(payload)
      if (success) {
        this.AcNotify({
          type: 'success',
          message,
          position: 'bottom',
        })
      } else {
        this.AcNotify({
          type: 'error',
          message,
          position: 'bottom',
        })
      }
    },
    onClose(refetch) {
      if (refetch) {
        const { selectedCompany } = this
        this.$emit('onClose', { selectedCompany })
      }

      this.reset()
      this.$emit('input', false)
    },
    async getSecurityList(val) {
      await this.fetchSecurityList(val)
    },
    selectSecurity(val) {
      const { securityList } = this
      const securityObj = securityList.find(obj => {
        return obj.name === val
      })
      if (securityObj != undefined) {
        this.stokeCode = securityObj.symbol
        this.exchange = securityObj.exch
      }
    },
    reset() {
      this.licenses = []
      this.$refs.form.reset()
    },
    getCompanyName(id) {
      const selectedCompany = this.companies.find(o => o.id === id)
      return selectedCompany?.name
    },
  },
  watch: {
    search(val) {
      val && val !== this.secasdasdurity && this.getSecurityList(val)
    },
    value: {
      handler(val) {
        if (val) {
          if (this.checkObject(this.editItem) && this.editItem != undefined) {
            const {
              accountNumber,
              approvalDate,
              approver,
              buyOrSell,
              exchange,
              gtcOrder,
              invsetmentClass,
              security,
              ticker,
              userName,
            } = this.editItem
            this.security = security
            this.exchange = exchange
            this.stokeCode = ticker
            this.userData = userName
            this.brokersNumber = accountNumber
            this.details = buyOrSell
            this.investmentClass = invsetmentClass
            this.gtcOrder = gtcOrder
            this.approver = approver
            this.joinDate = approvalDate
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
